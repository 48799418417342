@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;600&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Inconsolata', sans-serif;
}

:root {
  --primary: #F2F2F2;
  --black: #000000;
  --blackLighter: #000000;
  --grayLight: #F5F5F5;
  --grayMedium: #e5e5e5; 
  --white: #FFFFFF;
  

  --um: #0D0D0D;
  --dois: #202020;
  --tres: #424242;
  --quatro: #6b6b6b;
  --cinco: #9b9999;
  
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #000000;
  background-color: var(--black);
}

a {
  color: inherit;
  text-decoration: none;
} 

#root{
  min-height: calc(100vh - var(--bodyPaddingTop));
  display: flex;
  flex-direction: column;
}
.Logo {
    /* max-width: 168px; */
  }

  .Fe{
    width: 4.8%;
    position: fixed;
    margin-left: -65px;
    margin-top: -41px;
  }

  ul {
    color: var(--primary);
    list-style: none;
    text-transform: uppercase;
    /* display: flex; */
  }
  
  @media (max-width: 800px) {
    .Logo {
      max-width: 70px;
      margin: auto;
    }
  }
  
  .Menu {
    width: 100%;
    height: 94px;
    z-index: 100;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    padding-left: 5%;
    padding-right: 5%;
  
    background: var(--black);
    /* border-bottom: 2px solid var(--primary); */
  }

  .Title {
    font-weight: lighter;
    color: var(--primary);
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 10px;
  }
  
  .Title:hover {
    -webkit-filter: contrast(0.5);
            filter: contrast(0.5);
  }
  
  .SubMenu {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 800px) {
    .SubMenu {
      margin-top:17px;
    }

    .SubTitle{
      font-size: 13px;
    }
  }

  .SubTitle{
    font-weight: lighter;
    color: var(--primary);
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 10px;
    margin-left: 10px;
  }

  .SubTitle:hover{
    -webkit-filter: contrast(0.5);
            filter: contrast(0.5);
  }

  body {
    /* --bodyPaddingTop: 94px; */
    padding-top: var(--bodyPaddingTop);
  }
  @media (max-width: 800px) {
    .Menu {
      height: 40px;
      justify-content: center;
    }
    body {
      --bodyPaddingTop: 40px;
      padding-top: var(--bodyPaddingTop);
    }
  }
  
  .ButtonLink {
    color: var(--white);
    border: 1px solid var(--white);
    box-sizing: border-box;
    cursor: pointer;
    padding: 16px 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    transition: opacity .3s;
  }
  .ButtonLink:hover,
  .ButtonLink:focus {
    opacity: .5;
    
  }
  
  @media (max-width: 800px) {
    a.ButtonLink {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--primary);
      border-radius: 0;
      border: 0;
      text-align: center;
    }
  }
