* {
  box-sizing: border-box;
  font-family: 'Inconsolata', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;600&display=swap');

:root {
  --primary: #F2F2F2;
  --black: #000000;
  --blackLighter: #000000;
  --grayLight: #F5F5F5;
  --grayMedium: #e5e5e5; 
  --white: #FFFFFF;
  

  --um: #0D0D0D;
  --dois: #202020;
  --tres: #424242;
  --quatro: #6b6b6b;
  --cinco: #9b9999;
  
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: var(--black);
}

a {
  color: inherit;
  text-decoration: none;
} 

#root{
  min-height: calc(100vh - var(--bodyPaddingTop));
  display: flex;
  flex-direction: column;
}